<script setup lang="ts">
import type { GraphRun, IntegrationLink, StepRun } from '@respell/database';
import { formatDistanceToNow } from 'date-fns';
import TasksChart from '~/components/chart/TasksChart.vue';
import CreateCampaignModal from '~/components/modals/CreateCampaignModal.vue';

const agentStore = useAgentsStore();
const integrationStore = useIntegrationStore();
const modal = useModal();

const hubspotId = computed(() => {
  return integrationStore.linkedAccounts.find(
    (integration: IntegrationLink) => integration.service === 'hubspot',
  )?.accountId;
});

const { agentGraph, campaigns } = storeToRefs(agentStore);
const campaignId = useRouteParams('campaignId');

const agentGraphId = computed(() => {
  return agentGraph.value?.id;
});

const activeCampaign = computed(() =>
  campaigns.value.find(
    (campaign: GraphRun) => campaign.id === campaignId.value,
  ),
);

const { data: agentTasks } = await useApi(`/api/agents/sdr/tasks`, {
  query: {
    agentGraphId,
    campaignId,
  },
  key: 'SDR/tasks',
  watch: [activeCampaign],
});

const taskGroups = computed(() => {
  const createTaskGroup = (
    label: string,
    color: string,
    icon: string,
    filterSlug: string,
    mapTask: (task: StepRun) => any,
  ) => {
    const tasks = agentTasks.value
      ?.filter((task: StepRun) => task.slug === filterSlug)
      .map(mapTask);

    return {
      label,
      color,
      icon,
      tasks,
      count: tasks?.length ?? 0,
      campaign: activeCampaign.value,
    };
  };

  const getCampaign = (task: StepRun) =>
    campaigns.value.find((campaign: GraphRun) => campaign.id === task.runId);

  const companies = createTaskGroup(
    'Companies created',
    'text-orange-500',
    'i-ph-buildings',
    'update_crm_companies',
    (task: StepRun) => ({
      type: 'company',
      name: task.options?.value?.name,
      description:
        task.options?.value?.website_url ??
        task.options?.value?.linkedin_url ??
        task.options?.value?.angellist_url,
      campaign: getCampaign(task),
      link: `https://app.hubspot.com/contacts/${hubspotId.value}/record/0-2/${task.outputs?.companyId}`,
    }),
  );

  const contacts = createTaskGroup(
    'Contacts created',
    'text-green-500',
    'i-ph-user',
    'add_person_to_hubspot',
    (task: StepRun) => ({
      type: 'contact',
      name: task.options?.value?.details?.name,
      description: task.outputs?.email,
      campaign: getCampaign(task),
      link: `https://app.hubspot.com/contacts/${hubspotId.value}/record/0-1/${task.outputs?.hubspotObjectId}`,
    }),
  );

  const emails = createTaskGroup(
    'Emails sent',
    'text-blue-500',
    'i-ph-paper-plane-tilt',
    'send_email',
    (task: StepRun) => ({
      type: 'email',
      name: task.options?.subject,
      description: task.options?.to,
      campaign: getCampaign(task),
      link: 'https://mail.google.com/mail/u/0/#drafts',
    }),
  );

  return [companies, contacts, emails];
});

const taskColumns = [
  { key: 'label', label: 'Task' },
  { key: 'empty' },
  { key: 'campaign', label: 'Campaign' },
  { key: 'count', label: 'Quantity' },
];

const links = [
  {
    label: 'All campaigns',
    to: { name: 'agent.analytics', replace: true },
  },
  {
    label: activeCampaign.value?.name,
    isActive: true,
  },
];

const openCampaignSettings = () => {
  modal.open(CreateCampaignModal, {
    campaign: activeCampaign.value,
  });
};
</script>
<template>
  <div
    class="flex flex-col items-start gap-4 w-full"
    :class="{
      'border-container p-m bg-white w-full rounded-2xl': !!campaignId,
    }"
  >
    <span v-if="campaignId" class="flex justify-between items-start w-full">
      <span class="flex flex-col items-start gap-2">
        <UBreadcrumb :links="links" :ui="{ li: 'text-lg' }">
          <template #default="{ link, isActive, index }">
            <span class="flex items-center gap-2">
              <p
                :class="
                  isActive
                    ? 'text-primary-500'
                    : 'text-primary-300 hover:text-primary-400'
                "
                class="rounded-full truncate"
              >
                {{ link.label }}
              </p>
              <span v-if="index === 1" class="contents">
                <UIcon
                  v-if="activeCampaign?.state === 'inProgress'"
                  name="i-ph-arrow-clockwise"
                  class="text-xl animate-spin text-primary-500"
                />
                <UBadge
                  v-else
                  :color="
                    activeCampaign?.state === 'success' ? 'green' : 'primary'
                  "
                  :label="activeCampaign?.state"
                  :variant="
                    activeCampaign?.state === 'success' ? 'solid' : 'subtle'
                  "
                  :ui="{ rounded: 'rounded-2xl', font: 'capitalize' }"
                />
              </span>
            </span>
          </template>
        </UBreadcrumb>
        <p class="caption">
          Campaign started
          {{ formatDistanceToNow(activeCampaign?.createdAt) }} ago
        </p>
      </span>
      <UButton
        label="Campaign settings"
        icon="i-ph-gear"
        @click="openCampaignSettings"
      />
    </span>
    <UAlert
      v-if="!agentTasks?.length && activeCampaign?.state === 'success'"
      color="red"
      variant="subtle"
      title="Whoops, your agent couldn't find any leads."
      description="Sorry about that. Try broadening your next campaign's goals or adjusting the search criteria."
      class="w-full"
      :ui="{
        title: 'font-bold text-base',
      }"
    />
    <p class="subtitle">Stats</p>

    <TasksChart :task-groups="taskGroups" />

    <p class="subtitle">Tasks</p>
    <UTable
      :rows="taskGroups"
      :columns="taskColumns"
      class="w-full"
      :ui="{
        tr: { base: 'grid grid-cols-[2rem,minmax(0,1fr),20rem,10rem,12rem]' },
        td: { base: 'w-fit' },
      }"
    >
      <template #label-data="{ row }">
        <span class="flex items-center gap-2 justify-start">
          <UIcon :name="row.icon" class="text-xl" />
          <p class="truncate">{{ row.label }}</p>
        </span>
      </template>
      <template #campaign-data="{ row }">
        <UBadge
          :label="row.campaign?.name ?? 'All'"
          color="gray"
          variant="solid"
          :ui="{ rounded: 'rounded-lg' }"
        />
      </template>
      <template #expand="{ row }">
        <UTable
          :rows="row.tasks"
          :ui="{
            wrapper: 'border-none rounded-none mt-0',
            thead: 'hidden',
            tbody: 'bg-gray-50',
            tr: {
              base: 'grid grid-cols-[2rem,minmax(0,1fr),20rem,10rem,12rem]',
            },
            td: { base: 'w-fit', color: 'text-gray-900' },
          }"
        >
          <template #campaign-data="{ row }">
            <UButton
              :label="row.campaign?.name"
              color="gray"
              variant="solid"
              :ui="{ rounded: 'rounded-lg' }"
              size="xs"
              :to="{
                name: 'agent.analytics',
                params: { campaignId: row.campaign?.id },
                replace: true,
              }"
            />
          </template>
          <template #type-data="{ row }">
            <div />
          </template>
          <template #name-data="{ row }">
            <span class="flex grow max-w-96">
              <p class="truncate">{{ row.name }}</p>
            </span>
          </template>
          <template #description-data="{ row }">
            <span class="flex w-56">
              <ULink
                v-if="row.type === 'company'"
                :to="row.description"
                external
                target="_blank"
                active-class="text-primary"
                inactive-class="text-primary-300 hover:text-primary-400 "
                class="truncate"
              >
                {{ row.description }}
              </ULink>
              <p v-else class="truncate">{{ row.description }}</p>
            </span>
          </template>
          <template #link-data="{ row }">
            <UButton
              color="white"
              :to="row.link"
              external
              target="_blank"
              :label="row.type === 'email' ? 'View drafts' : 'View in CRM'"
              trailing-icon="i-ph-arrow-square-out"
            />
          </template>
        </UTable>
      </template>
    </UTable>
  </div>
</template>
