<script setup lang="ts">
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar } from 'vue-chartjs';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
);

const { taskGroups } = defineProps<{
  taskGroups: { label: string; count: number }[];
}>();

const chartData = computed(() => ({
  labels: taskGroups.map((group) => group.label),
  datasets: [
    {
      data: taskGroups.map((group) => group.count),
      backgroundColor: [
        'rgba(249, 115, 22, 0.8)', // orange-500
        'rgba(34, 197, 94, 0.8)', // green-500
        'rgba(59, 130, 246, 0.8)', // blue-500
      ],
      borderColor: [
        'rgb(249, 115, 22)', // orange-500
        'rgb(34, 197, 94)', // green-500
        'rgb(59, 130, 246)', // blue-500
      ],
      borderWidth: 1,
    },
  ],
}));

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
    },
  },
  scales: {
    x: {
      display: true,
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
    y: {
      display: true,
      beginAtZero: true,
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
  },
  barPercentage: 0.2, // Adjust this value to change bar width
};
</script>

<template>
  <div class="flex gap-3 w-full">
    <div
      v-for="taskGroup in taskGroups"
      :key="taskGroup.label"
      class="flex flex-col gap-6 p-6 w-full rounded-xl border border-container"
    >
      <span class="flex gap-1">
        <UIcon
          name="i-ph-circle-fill"
          class="text-sm"
          :class="taskGroup.color"
        />
        <p class="body-sm">{{ taskGroup.label }}</p>
      </span>
      <p class="text-5xl font-bold">{{ taskGroup.count }}</p>
    </div>
  </div>
  <div
    class="border-container p-m bg-white w-full gap-3 flex flex-col rounded-2xl items-start"
  >
    <div class="flex flex-col items-start">
      <span class="flex items-baseline gap-2">
        <p class="title">
          {{ taskGroups.reduce((acc, group) => acc + group.count, 0) }}
        </p>
        <p class="body-sm dimmed">total tasks</p>
      </span>
    </div>

    <client-only>
      <div style="height: 150px; width: 100%">
        <Bar :data="chartData" :options="chartOptions" />
      </div>
    </client-only>
  </div>
</template>
